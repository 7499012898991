$loading-speed: 2s;

html, body {
    display: flex;
    flex: auto;
    align-self: stretch;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    #root > .loading {
        flex: auto;
        align-self: stretch;
        &::after {
            animation: loading $loading-speed infinite linear;
            width: 1.6rem;
            height: 1.6rem;
            margin-left: -.8rem;
            margin-top: -.8rem;
            border-width: .2rem;
        }
        &::before {
            display: block;
            animation: loading $loading-speed infinite linear;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 3.2rem;
            height: 3.2rem;
            padding: 0;
            margin-left: -1.6rem;
            margin-top: -1.6rem;
            background: 0 0;
            border: .2rem solid #5755d9;
                border-top-color: rgb(87, 85, 217);
                border-right-color: rgb(87, 85, 217);
                border-left-color: transparent;
                border-bottom-color: transparent;
            border-radius: 50%;
            content: "";
            opacity: 1;
            z-index: 1;
        }
        .lds-gear {
            &::before {
                display: block;
                animation: loading $loading-speed infinite linear;
                position: absolute;
                left: 50%;
                top: 50%;
                width: 6.4rem;
                height: 6.4rem;
                padding: 0;
                margin-left: -3.2rem;
                margin-top: -3.2rem;
                background: 0 0;
                border: .1rem solid #5755d9;
                    border-left-color: rgb(87, 85, 217);
                    border-bottom-color: rgb(87, 85, 217);
                    border-top-color: transparent;
                    border-right-color: transparent;
                border-radius: 50%;
                content: "";
                opacity: 1;
                z-index: 1;
            }
            &::after {
                display: block;
                animation: loading $loading-speed infinite linear;
                position: absolute;
                left: 50%;
                top: 50%;
                width: .8rem;
                height: .8rem;
                padding: 0;
                margin-left: -.4rem;
                margin-top: -.4rem;
                background: 0 0;
                border: .1rem solid #5755d9;
                    border-top-color: rgb(87, 85, 217);
                    border-right-color: rgb(87, 85, 217);
                    border-left-color: transparent;
                    border-bottom-color: transparent;
                border-radius: 50%;
                content: "";
                opacity: 1;
                z-index: 1;
            }
        }
    }
}
